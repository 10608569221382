body{
	background: #121212;
	font-family: sans-serif;
	color: white;
	padding-top: 25px;
}

.atpayLogo{
	width: 25%;
}

.buttonsContainer{
	padding: 1rem 10%;
}

.accountId{
	white-space: nowrap;  
   	width: 100%;                     
   	overflow: hidden;
   	text-overflow: ellipsis;
}


.walletCard {
	text-align: left;
    width: 50%;
    margin: auto;
    color: white;
    border-radius: 5px;
    padding: 22px 20px 22px 20px;
    border-style: solid;
    border-color: rgb(27, 92, 254, 0.5);
    background-color: rgb(27, 92, 254, 0.1);
    margin-top: 25px;
}

.walletCard h3 {
	margin-left:1em;
	margin-bottom: 1em;
	margin-top: 1em;
}
.parent{
	width: 100%;
	justify-content: center;
	display: flex;
}
.interactionsCard {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 50%;
    margin: auto;
    color: white;
    border-radius: 5px;
    padding: 22px 20px 22px 20px;
    border-style: solid;
    border-color: rgb(27, 92, 254, 0.5);
    background-color: rgb(27, 92, 254, 0.1);
    margin-top: 25px;
}

.button6 {
	padding: 22px 20px 22px 20px;
    background: #1B5CFE;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border: none;
    margin-right: 25px;
	margin-bottom: 20px;
}

.maxWidth{
	width: 100%;
}
input {
	width: 95%;
	padding: 10px;
}
.bold{
	font-weight: bold;
}

.interactionsCardItem{
	width: 100%;
	margin: 0.5rem 0;
}

.checkItem input{
	width: auto;
}

.checkItem a{
	text-decoration: none;
	color: dodgerblue;
}

@media only screen and (max-width: 768px) {
	.button6{
		width: 100%;
	}
	.walletCard{
		width: auto;
		margin: 0 1rem;
	}
	.interactionsCard{
		width: 82%;
		margin: 0 1rem;
		margin-top: 1rem;
	}

	input{
		width: 92%;
	}

	.parent{
		display: block;
	}
	
}